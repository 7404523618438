import './header-menu.js';

class HeaderScroll {
  constructor() {
    this.headerWrapper = document.querySelector('.header-wrapper')

    // Methods
    this.onHeaderScroll = this.onHeaderScroll.bind(this);
    
    window.addEventListener('scroll', this.onHeaderScroll );

    this.onHeaderScroll();

  }

  onHeaderScroll(event) {
    const currentScroll = document.documentElement.scrollTop;
    if ( currentScroll > 5 && !this.headerWrapper.classList.contains('on-scroll') ) {
      this.headerWrapper.classList.add('on-scroll');
    } else if ( currentScroll <= 5) {
      this.headerWrapper.classList.remove('on-scroll');
    }
  }

}

new HeaderScroll();