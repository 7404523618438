// Animatin helpers
const headerMenuAnimation = {
  
  // element, height, duration, delay, onAnimationFinish, onAnimationBefore
  slideUp: function(options) {

    const delay = options.hasOwnProperty('delay') ? options.delay : 0;
    const duration = options.hasOwnProperty('duration') ? options.duration : 200;
    const slideDownAnimation = options.element.animate({
      height: [`${options.height}px`, 0],
    opacity: [1, 0],
    }, {
      delay: delay,
      duration: duration, 
      fill: 'forwards',
    });

    typeof options.onAnimationBefore === 'function' && options.onAnimationBefore(slideDownAnimation);

    slideDownAnimation.onfinish = () => {
      options.element.style.removeProperty('height');
      options.element.style.removeProperty('opacity');
      slideDownAnimation.cancel();
      typeof options.onAnimationFinish === 'function' && options.onAnimationFinish(slideDownAnimation);
    }
  },

  // element, height, duration, delay, onAnimationFinish, onAnimationBefore
  slideDown: function(options) {

    const delay = options.hasOwnProperty('delay') ? options.delay : 0;
    const duration = options.hasOwnProperty('duration') ? options.duration : 200;
    const slideDownAnimation = options.element.animate({
      height: [0, `${options.height}px`],
      opacity: [0, 1],
    }, {
      delay: delay,
      duration: duration, 
      fill: 'forwards',
    });

    typeof options.onAnimationBefore === 'function' && options.onAnimationBefore(slideDownAnimation);

    slideDownAnimation.onfinish = () => {
      options.element.style.setProperty('height', `${options.height}px`);
      options.element.style.setProperty('opacity', 1);
      slideDownAnimation.cancel();
      typeof options.onAnimationFinish === 'function' && options.onAnimationFinish(slideDownAnimation);
    }
  },

  // element, height, onAnimationFinish, onAnimationBefore
  fadeOut: function(options) {
    
    const delay = options.hasOwnProperty('delay') ? options.delay : 0;
    const duration = options.hasOwnProperty('duration') ? options.duration : 200;
    const fadeOutAnimation = options.element.animate({
      opacity: [1, 0],
    }, {
      duration: duration,
      delay: delay,
      fill: 'forwards',
    });

    typeof options.onAnimationBefore === 'function' && options.onAnimationBefore(fadeOutAnimation);

    fadeOutAnimation.onfinish = () => {
      options.element.style.removeProperty('height');
      options.element.style.removeProperty('opacity');
      options.element.classList.remove('active');
      fadeOutAnimation.cancel();

      typeof options.onAnimationFinish === 'function' && options.onAnimationFinish(fadeOutAnimation);
    }; 

  }
};

export { headerMenuAnimation };