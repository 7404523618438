exports = module.exports = mediaQueries;

function mediaQueries() {
  return {
    'bigdesktop-layout': 1240,
    'mobilemenu-layout': 720,
    'desktop-layout': 960,
    'tablet-layout': 721,
    'mobile-layout': 720
  }
};