import 'web-animations-js';

class FlexiblePageRadMore {
  
  constructor() {
    const readMore = document.querySelectorAll('.grid-image-text');
    readMore.forEach( item => this.checkReadMoreFlag(item) );
  }

  checkReadMoreFlag(item) {
    if (item.querySelector('.section--read-more-flag')) {
      new ReadMoreTrigger(item);
    }
  }

}

class ReadMoreTrigger {
  constructor(item) {
    this.wrapper = item;
    this.trigger = item.querySelector('.section--read-more-flag');
    this.onReadMoreClick = this.onReadMoreClick.bind(this);
    this.trigger.addEventListener('click', this.onReadMoreClick );
  }

  onReadMoreClick(event) {
    event.preventDefault();
    this.trigger.classList.toggle('active');
    if (this.trigger.classList.contains('active')) {
      this.showContent();
    } else {
      this.hideContent();
    }
  }

  showContent() {
    const options = {
      duration: 150, 
      fill: 'forwards',
    };

    this.animateContent(options, (additionalSection) => {
      additionalSection.style.height = '';
    });
  }

  hideContent() {
    const options = {
      duration: 150, 
      fill: 'forwards',
      direction: 'reverse'
    };
    
    this.animateContent(options, (additionalSection) => {
      additionalSection.style.height = '0';
    });
  }

  animateContent(options, onFinish) {
    const additionalSection = this.wrapper.querySelector('.section--read-more-wrapper');
    const additionalSectionHeight = this.wrapper.querySelector('.section--read-more').getBoundingClientRect().height;

    const slideDownAnimation = additionalSection.animate({
      height: [0, `${additionalSectionHeight}px`],
      opacity: [0, 1],
    }, options);

    slideDownAnimation.onfinish = () => {
      typeof onFinish === 'function' && onFinish(additionalSection);
      slideDownAnimation.cancel();
    }
  }

}


export { FlexiblePageRadMore };