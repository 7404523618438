import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { headerMenuAnimation } from './header-menu-animation';
import Misc from './base/misc.js';
import 'web-animations-js';

class HeaderMenu {
  constructor() {
    this.mainWrapper = document.querySelector('.main-wrapper');
    this.headerWrapper = document.querySelector('.header-wrapper');
    this.headerMenu = document.querySelector('.header-menu');
    this.mobileMenuSize = Misc.getMediaQueries()['mobilemenu-layout'];
    this.trigger = document.querySelector('.item-menu-trigger');
    this.subMenuCloseTrigger = document.querySelector('.header-sub-menu--close');
    this.subMenuBackTrigger = document.querySelector('.header-sub-menu--back');
    
    // Methods
    this.onTriggerClick = this.onTriggerClick.bind(this);
    this.handleMobileMenu = this.handleMobileMenu.bind(this);
    this.handleSubMenu = this.handleSubMenu.bind(this);

    // Add Icon Menu trigger 'click' event
    this.trigger.addEventListener('click', this.onTriggerClick);

    [].slice.call(document.querySelectorAll('.menu-item-has-children')).forEach(item => {
      item.firstElementChild.addEventListener('click', this.handleSubMenu)
    })

    // Handle Mobile menu
    window.addEventListener('resize', this.handleMobileMenu);
    if (Misc.getViewport().width <= this.mobileMenuSize) {
      this.handleMobileMenu();
    }
}

  onTriggerClick(event) {
    event.preventDefault()

    // Open menu
    if (!document.body.classList.contains('header-menu--on')) {
      this.openMenu();
    } else {
      this.closeMenu();
    }
  }

  isMenuOpen() {
    return document.body.classList.contains('header-menu--on');
  }

  openMenu() {
    document.body.classList.add('header-menu--on');
    this.expandMenu()
  }

  closeMenu() {
    document.body.classList.remove('header-menu--on');
    this.compressMenu();
  }

  getMenuNavHeight() {
    return this.headerMenu.querySelector('nav').getBoundingClientRect().height;
  }

  expandMenu() {
    const menuNavHeight = this.getMenuNavHeight();
    const menuHeight = document.body.querySelector('.header-menu nav ul').getBoundingClientRect().height;
    const viewportHeight = Misc.getViewport().height;

    this.headerWrapper.style.height = `${viewportHeight}px`;

    const headerAnimation = this.headerWrapper.animate({
      backgroundColor: ['transparent', '#252528']
    }, {
      duration: 200, 
      fill: 'forwards',
    });

    headerAnimation.onfinish = () => {
      this.headerWrapper.style.setProperty('background-color', '#252528');
      headerAnimation.cancel();
    }

    headerMenuAnimation.slideDown({
      element: this.headerMenu,
      height: menuNavHeight, 
      onAnimationFinish: () => {
        disableBodyScroll(this.headerWrapper)
      }
    });
  }

  compressMenu() {
    const menuHeight = document.body.querySelector('.header-menu nav ul').getBoundingClientRect().height;
    
    headerMenuAnimation.fadeOut({
      element: this.headerMenu,
      onAnimationFinish: () => {
        this.headerWrapper.style.removeProperty('height');
        // this.triggerMenu.classList.remove('active');
        enableBodyScroll(this.headerWrapper);
      }
    });

    const headerAnimation = this.headerWrapper.animate({
      background: ['#212224', 'transparent']
    }, {
      duration: 200, 
      fill: 'forwards',
    });

    headerAnimation.onfinish = () => {
      this.headerWrapper.style.removeProperty('background-color');
      this.headerWrapper.style.removeProperty('opacity');
      headerAnimation.cancel();
    }

  }

  handleMobileMenu() {
    if (Misc.getViewport().width <= this.mobileMenuSize) {
      // console.log('mobile!')
    } else {
      if ( this.isMenuOpen() ) {
        this.closeMenu();
      }
    }
  }

  handleSubMenu(event) {
    event.preventDefault();

    if (Misc.getViewport().width > this.mobileMenuSize) {
      return false;
    }
      
    event.preventDefault();
    const subMenu = event.currentTarget.parentElement.querySelector('.sub-menu');
    const subMenuHeight = this.getSubMenuHeight(subMenu);
    if (!event.currentTarget.classList.contains('active') ) {
      this.openSubMenu(event, subMenu, subMenuHeight);
    } else {
      this.closeSubMenu(event, subMenu, subMenuHeight);
    }
  }

  openSubMenu(event, subMenu, subMenuHeight) {
    headerMenuAnimation.slideDown({
      element: subMenu,
      height: subMenuHeight,
      onAnimationBefore: () => {
        this.headerMenu.style.height = `${this.headerMenu.getBoundingClientRect().height + subMenuHeight}px`;
        event.currentTarget.classList.add('active');
      }
    });
  }

  closeSubMenu(event, subMenu, subMenuHeight) {
    headerMenuAnimation.slideUp({
      element: subMenu,
      height: subMenuHeight,
      onAnimationBefore: () => {
        event.currentTarget.classList.remove('active');
      },
      onAnimationFinish: () => {
        this.headerMenu.style.height = `${this.headerMenu.getBoundingClientRect().height - subMenuHeight}px`;
      }
    });
  }

  openSubMenu(event, subMenu, subMenuHeight) {
    headerMenuAnimation.slideDown({
      element: subMenu,
      height: subMenuHeight,
      onAnimationBefore: () => {
        this.headerMenu.style.height = `${this.headerMenu.getBoundingClientRect().height + subMenuHeight}px`;
        event.currentTarget.classList.add('active');
      }
    });
  }

  closeSubMenu(event, subMenu, subMenuHeight) {
    headerMenuAnimation.slideUp({
      element: subMenu,
      height: subMenuHeight,
      onAnimationBefore: () => {
        event.currentTarget.classList.remove('active');
      },
      onAnimationFinish: () => {
        this.headerMenu.style.height = `${this.headerMenu.getBoundingClientRect().height - subMenuHeight}px`;
      }
    });
  }

  getSubMenuHeight(subMenu) {
    let totalHeight = 0;
    [].slice.call(subMenu.querySelectorAll('li')).forEach( item => {
      totalHeight += item.getBoundingClientRect().height;
    })
    return totalHeight;
  }

}

new HeaderMenu();
